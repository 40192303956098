import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getConsumptionData } from "../../redux/apiCalls/dataCall";
import { Toast } from "../../helpers/alert";
import { downloadExcel } from "./functionDownload";

const ProrrateoBoton = ({ filter }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.currentUser);
  const prorrateo2023data = useSelector((state) => state.data.prorrateo2023);
  const prorrateo2024Data = useSelector((state) => state.data.prorrateo2024);
  const prorrateo2025Data = useSelector((state) => state.data.prorrateo2025);
  const offices = useSelector((state) => state.companies.companies);

  const [yearGenerate, setYearGenerate] = useState({
    2023: false,
    2024: false,
    2025: false,
  });

  const [yearSelected, setYearSelected] = useState(null);

  useEffect(() => {
    if (yearGenerate["2023"] && !prorrateo2023data) {
      Toast.fire({
        icon: "info",
        title: "Pronto iniciará la descarga, por favor aguarde.",
        timer: 5000,
      });
      getConsumptionData(dispatch, user?.accessToken, "2023").then((res) => {
        downloadExcel(
          "2023",
          setYearSelected,
          yearSelected,
          res,
          setYearGenerate,
          offices,
          filter
        );
      });
    }
  }, [yearGenerate["2023"]]);

  useEffect(() => {
    if (yearGenerate["2024"] && !prorrateo2024Data) {
      Toast.fire({
        icon: "info",
        title: "Pronto iniciará la descarga, por favor aguarde.",
        timer: 5000,
      });
      getConsumptionData(dispatch, user?.accessToken, "2024").then((res) => {       
        downloadExcel(
          "2024",
          setYearSelected,
          yearSelected,
          res,
          setYearGenerate,
          offices,
          filter
        );
      });
    }
  }, [yearGenerate["2024"]]);


  useEffect(() => {
    if (yearGenerate["2025"] && !prorrateo2025Data) {
      Toast.fire({
        icon: "info",
        title: "Pronto iniciará la descarga, por favor aguarde.",
        timer: 5000,
      });
      getConsumptionData(dispatch, user?.accessToken, "2025").then((res) => {
        downloadExcel(
          "2025",
          setYearSelected,
          yearSelected,
          res,
          setYearGenerate,
          offices,
          filter
        );
      });
    }
  }, [yearGenerate["2025"]]);


  const handleGenerateReport = (year) => {
    
    if (yearGenerate[year]) {
      Toast.fire({
        icon: "warning",
        title: `El reporte ${year} ya ha sido generado. Descargando...`,
      });
      // Simula la descarga directamente
      downloadExcel(
        year,
        setYearSelected,
        yearSelected,
        year === "2023" ? prorrateo2023data : year === "2024" ? prorrateo2024Data : prorrateo2025Data,
        setYearGenerate,
        offices,
        filter
      );
    } else {
      setYearGenerate((prev) => ({ ...prev, [year]: true }));
    }
  };

  return (
    <div>
      <button
        onClick={() => handleGenerateReport("2023")}
        disabled={yearGenerate["2023"] && !prorrateo2023data}
        className="template-bar button"
        style={{
          borderTopWidth: "0px",
          borderRightWidth: "0px",
          borderBottomWidth: "0px",
          borderLeftWidth: "0px",
        }}
      >
        <li  style={{display: "contents", fontWeight: "bold"}}>
        Reporte Consumos 2023
        </li>
      </button>
      <button
        onClick={() => handleGenerateReport("2024")}
        disabled={yearGenerate["2024"] && !prorrateo2024Data}
        className="template-bar button"
        style={{
          borderTopWidth: "0px",
          borderRightWidth: "0px",
          borderBottomWidth: "0px",
          borderLeftWidth: "0px",
        }}
      >
        <li style={{display: "contents", fontWeight: "bold"}}>
        Reporte Consumos 2024
        </li>
      </button>
      <button
        onClick={() => handleGenerateReport("2025")}
        disabled={yearGenerate["2025"] && !prorrateo2025Data}
        className="template-bar button"
        style={{
          borderTopWidth: "0px",
          borderRightWidth: "0px",
          borderBottomWidth: "0px",
          borderLeftWidth: "0px",
        }}
      >
        <li style={{display: "contents", fontWeight: "bold"}}>
        Reporte Consumos 2025
        </li>
      </button>
    </div>
  );
};

export default ProrrateoBoton;
