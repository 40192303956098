import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAnualData } from "../../redux/apiCalls/dataCall";
import { Toast } from "../../helpers/alert";
import { downloadExcel } from "../Reports/ReportsExcel";

const CompletitudBoton = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.currentUser);
  const anualData = useSelector((state) => state.data.anualData);
  const totalInvoices = useSelector((state) => state.data.anualData?.totalInvoices);
  const [isFetching, setIsFetching] = useState(false);
  const [triggerDownload, setTriggerDownload] = useState(false);

  const handleGenerateReport = async () => {
    if (!anualData?.totalInvoices && !isFetching) {
      setIsFetching(true);
      setTriggerDownload(true); 
      try {
      Toast.fire({
                icon: "info",
                title: "Pronto iniciará la descarga del reporte de completitud, por favor aguarde.",
                timer: 5000,
        });
        await getAnualData(dispatch, user?.accessToken);
      } catch (error) {
        console.error("Error fetching annual data:", error);
      } finally {
        setIsFetching(false);
      }
    }
  
    if (anualData?.totalInvoices) {
      Toast.fire({
        icon: "warning",
        title: `El reporte ya ha sido generado. Descargando...`,
      });
      downloadExcel(totalInvoices);
    }
  };
  
// useEffect para observar cambios en isFetching, anualData, y triggerDownload
useEffect(() => {
  if (!isFetching && anualData?.totalInvoices && triggerDownload) {
    Toast.fire({
      icon: "warning",
      title: `El reporte ya ha sido generado. Descargando...`,
    });

    downloadExcel(anualData.totalInvoices); // Asegúrate de usar la fuente correcta de datos
    setTriggerDownload(false); // Resetear la bandera para evitar múltiples descargas
  }
}, [isFetching, anualData, triggerDownload]);

  return (
    <div>
      <button
        onClick={handleGenerateReport}
        className="template-bar button"
        style={{
          borderTopWidth: "0px",
          borderRightWidth: "0px",
          borderBottomWidth: "0px",
          borderLeftWidth: "0px",
        }}
      >
        <li style={{ display: "contents", fontWeight: "bold" }}>
          Completitud de cargas facturas
        </li>
      </button>
    </div>
  );
};

export default CompletitudBoton;
