import "./DashConsumption.css";
import React from "react";
import BarChart from "../../Charts/BarChart";
import TableComponent from "../TableComponent/TableComponent";
import { provinces } from "../../../helpers/infoFilter";
import { FaBars } from "react-icons/fa";
import BillsExcel from "../../Reports/ReportsBillsExcel";
import { useSelector } from "react-redux";
import TableVendors from "../TableComponent/TableVendors";
import TableConsumpAndCosts from "../TableComponent/TableConsumpAndCosts";
import ReportEnegiaPdf from "../../Reports/ReportEnergiaPdf";
import DonutUtility from "../../Charts/DonutUtility";
import { normalizeText } from "../../../helpers/functions";
import ProrrateoBoton from "../ProrrateoBoton";
import CompletitudBoton from "../CompletitudBoton";

const DashConsumption = ({ data, filterOptions, filterClass, co2 }) => {
  let selectDates =  localStorage?.getItem("SelectDates");
  selectDates = JSON.parse(selectDates)
  const offices = useSelector((state) => state.companies.companies);
  const dataFetching = useSelector((state) => state.data.isFetching);
  const countBills = [...new Set(data?.map((item) => item?.Bill_ID))];
  let filteredTotal;
  const filteredServices = data?.filter(
    (item) =>
      item?.consumption !== null &&
      !isNaN(parseFloat(item?.consumption)) &&
      isFinite(item?.consumption) &&
      item?.consumption !== " " &&
      (item?.class === "Energía Consumida" || item?.class === "Energia Consumida" || item?.class === "Energia Inyectada" || item?.class === "Energia Reactiva" || item?.class === "Energía Reactiva" || item?.class === "Potencia Contratada" || item?.class === "Coseno fi" || item?.class === "Tangente fi" || item?.class === "Potencia Demandada" || item?.class === "Potencia Excedida" || item?.class === "Agua Consumida" || item?.class === "Gas Cons. a 9300 Kcal/m3"
      || item?.class === "Gas Consumido" || item?.class === "Energia Renovable" || item?.class === "Potencia Renovable" || item?.class === "Energia Autogenerada")
  );

  if (filterClass.class.length === 0) {
    filteredTotal = data?.filter(
      (item) =>
        item?.consumption !== null &&
        !isNaN(parseFloat(item?.consumption)) &&
        isFinite(item?.consumption) &&
        item?.consumption !== " " &&
        (item?.class === "Energía Consumida" || item?.class === "Energia Consumida"
        || item?.class === "Agua Consumida" || item?.class === "Gas Cons. a 9300 Kcal/m3")
    );
  } else {
    filteredTotal = data?.filter(
      (item) =>
        item?.consumption !== null &&
        !isNaN(parseFloat(item?.consumption)) &&
        isFinite(item?.consumption) &&
        item?.consumption !== " " &&
        item?.class !== "Coseno fi" && 
        item?.class !== "Tangente fi" &&
        item?.class !== "IVA"
    );
  }

  const utilityTypes1 = ["GAS NATURAL", "AGUA", "ELECTRICIDAD"];
  const totalServices = utilityTypes1.reduce((acc, utilityType) => {
    const filteredData = filteredServices?.filter(
      (item) =>
        (item?.utilityType && item?.utilityType.trim() === utilityType?.trim())
    );
    
    let totalCost;
    if (utilityType === "ELECTRICIDAD" || utilityType === "GAS NATURAL") {
      totalCost = filteredData?.reduce((acc, item) => {
        const consumption = parseFloat(item?.consumption);
        const classItem = normalizeText(item?.class)
        if (!isNaN(consumption)) {
          // Check if the 'class' property exists in accumulator
          if (!acc[classItem]) {
            acc[classItem] = {
              costTotal: 0,
              units: item.units,
              class: classItem
            };
          }
          // Accumulate the cost for each 'class'
          acc[classItem].costTotal += consumption > 0 ? consumption : 0;
        }
        return acc;
      }, {});
      
      // se convierte en un array lo acumulado
      totalCost = Object.values(totalCost).map(cost => {
        if (cost?.class === "Coseno fi" || cost?.class === "Tangente fi") {
          cost.costTotal = cost?.costTotal / offices?.length;
        }
        return {
          ...cost,
          costTotal: !isNaN(cost?.costTotal) ? cost?.costTotal?.toLocaleString('es-AR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) : "No hay consumo"
        };
      });
    } else {
      totalCost = filteredData?.reduce((acc, item) => {
        const consumption = parseFloat(item?.consumption);
        if (!isNaN(consumption)) {
          return {
            costTotal: (acc.costTotal ? acc.costTotal : 0) + (consumption > 0 ? consumption : 0),
            units: item?.units,
            class: item?.class
          };
        }
        return acc;
      }, { costTotal: 0 });
      totalCost = totalCost.costTotal > 0 ? [totalCost] : [{ costTotal: "No hay consumo", units: "", class: "" }];
    }
  
    // Store results in accumulator
    acc[utilityType] = totalCost;
    return acc;
  }, {});
  
  const nameProvinces = filterOptions?.provinces
    .map((value) => {
      const province = provinces.find((p) => p.value === value);
      return province ? province.name : value;
    })
    .join(", ");
    
    const formatInvoiceDataForExcel = (invoices) => {
      
      return invoices?.map((invoice) => {
        const formattedInvoice = {
          "nombre del sitio": invoice?.locationName,
          utilidad: invoice?.utilityType,
          proveedor: invoice?.supplier,
          nroCuenta: invoice?.AccountNumber,
          factura: invoice?.Bill_ID,
          lecturaActual: invoice?.readingDate,
          lecturaAnterior: invoice?.previousDate,
          emision: invoice?.invoiceDate,
          vencimiento: invoice?.dueDate,
          costo: invoice?.cost,
          consumo: invoice?.consumption,
          lineItemText: invoice?.lineItemText,
          class: invoice?.class,
          m2: invoice?.m2,
          units: invoice?.units
   
        };
 
        return formattedInvoice;
      });
    };

    const consumoSostentabilidad = {};

    // Obtener el mes y año actual
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();
    
    // Obtener el mes y año hace un año
    const lastYearDate = new Date(currentDate);
    lastYearDate.setFullYear(currentYear - 1);
    const lastYearMonth = 0; // Comienza desde enero del año pasado
    const lastYearYear = lastYearDate.getFullYear();
    
    // Función para obtener el nombre del mes a partir de su número
    const getMonthName = (monthNumber) => {
      const months = [
        "enero", "febrero", "marzo", "abril", "mayo", "junio",
        "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"
      ];
      return months[monthNumber];
    };
    
    const monthsToFetch = [];
    for (let year = lastYearYear; year <= currentYear; year++) {
      const startMonth = (year === lastYearYear) ? lastYearMonth : 0;
      const endMonth = (year === currentYear) ? currentMonth : 11;
    
      for (let month = startMonth; month <= endMonth; month++) {
        monthsToFetch.push({ year, month });
      }
    }
    
    monthsToFetch.forEach(({ year, month }) => {
      const monthName = getMonthName(month);
      const monthDataKey = `${monthName} ${year}`;
      const monthData = co2 && co2.groupedByMonth ? co2.groupedByMonth[monthDataKey] : undefined;
    
      // Inicializar consumoSostentabilidad[monthDataKey] si no está definido
      if (!consumoSostentabilidad[monthDataKey]) {
        consumoSostentabilidad[monthDataKey] = [];
      }
    
      // Agregar datos existentes para el mes actual
      if (monthData && monthData?.length > 0) {
        monthData.forEach(data => {
          consumoSostentabilidad[monthDataKey].push({
            office: data?.office,
            consumoTotalMes: parseFloat(data?.consumoTotalMes),
            mesProrrateado: data?.mesProrrateado,
            unidad: data?.unidad || "",
            tiposConsumo: data?.tiposConsumo || [],
            days: data?.diasCalculados
          });
        });
      }
    
      offices?.forEach(office => {
        if (!consumoSostentabilidad[monthDataKey].some(item => item?.office === office?.name)) {
          consumoSostentabilidad[monthDataKey].push({
            office: office?.name,
            consumoTotalMes: 0.00,
            mesProrrateado: monthDataKey,
            unidad: "",
            tiposConsumo: [],
          });
        }
      });
    });
    


  const dataElectricity = data?.filter((d) => {
    const unit = d?.units?.toUpperCase();
    return (
      d?.utilityType === "ELECTRICIDAD" &&
      d?.lineItemText !== "Total Factura" &&
      ((filterClass?.class?.length > 0 ? (unit === "KWH" || unit === "KVAR" || unit === "KW") : unit === "KWH") || ((d?.class === "Energia Consumida" || d?.class === "Energía Consumida" || d?.class === "Energia consumida") && parseFloat(d?.cost) > 0 && (parseFloat(d?.consumption) === 0 || !d?.consumption || (d?.consumption && (unit === "N/A" || unit === "AR" || !unit)))))
    );
  });


  const dataGas = data?.filter((d) => {
    return (
      d?.utilityType === "GAS NATURAL" &&
      d?.lineItemText !== "Total Factura" &&
      (filterClass?.class?.length === 0 && d?.class === "Gas Cons. a 9300 Kcal/m3")
    );
  });

  const dataWater = data?.filter((d) => {
    return (
      d?.utilityType === "AGUA" &&
      d?.lineItemText !== "Total Factura"
    );
  });

  const areDatesOnly =
  selectDates?.startDate &&
  selectDates?.endDate &&
  filterOptions?.date?.length === 0 &&
  filterOptions?.provinces?.length === 0 &&
  filterOptions?.companies?.length === 0 &&
  filterOptions?.utilities?.length === 0 &&
  filterOptions?.class?.length === 0;

// Obtener el startDate y restar un día
const startDate = new Date(selectDates?.startDate);
startDate.setDate(startDate.getDate() - 1);

// Obtener el mes de startDate (se suma 1 porque getMonth devuelve valores de 0 a 11)
const startMonth = startDate.getMonth() + 1;

// Obtener el mes actual y sumarle 1 día
currentDate.setDate(currentDate.getDate() + 1);


// Condición para mostrar el botón de descarga
const showDownloadButton = startMonth !== currentMonth || !areDatesOnly;

//Ordenar los cuadritos de total por clase 
const electricidad = totalServices["ELECTRICIDAD"] || [];
const classOrder = [
  "Energia Consumida",
  "Energia Reactiva",
  "Energia Inyectada",
  "Potencia Contratada",
  "Potencia Demandada",
  "Potencia Excedida",
  "Energia Renovable",
  "Potencia Renovable",
  "Energia Autogenerada"
];

const orderedElectricidad = classOrder
  .map(className => electricidad?.filter(item => item?.class === className))
  .flat();

  
  return (
    <>
      {dataFetching && (
        <div style={{ position: "fixed", top: "0", left: "0", width: "100%", height: "100%", backgroundColor: "rgba(255, 255, 255, 0.8)", zIndex: "9999", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <div style={{ textAlign: "center" }}>
            <div className="spinner-border" role="status" style={{ width: "4rem", height: "4rem" }}>
              <span className="visually-hidden">Cargando...</span>
            </div>
            <p style={{ marginTop: "10px" }}>Cargando...</p>
          </div>
        </div>
      )}
      <div className="template-bar" style={{ opacity: dataFetching ? "0.5" : "1" }}>
        <div className="template-title">
          <p className="title">REPORTE DE ENERGÍA</p>
          <ul className="cont-nav">
            <li className="nav-item dropdown">
              <a
                className="nav-link nav-link-custom dropdown-toggle topbar-dropdown"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                REPORTES
                <FaBars />
              </a>
              <ul className="dropdown-menu" aria-label="Board">
                <li>
              { showDownloadButton && (
                    <button>
                      {data ? (
                        <BillsExcel data={formatInvoiceDataForExcel(data)} date={filterOptions}/>
                      ) : null}
                    </button>
                )}
                </li>
                <li>
              <ProrrateoBoton filter={filterOptions?.companies !== undefined ? filterOptions?.companies : []}/>
                </li> 
                <li style={{ width: "95%" }}>
                    <CompletitudBoton  />
                </li>
                <li>
                  <button>
                    <ReportEnegiaPdf filterOptions ={filterOptions}/>
                  </button>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div className="template-filters">
          <p>
          {
          selectDates?.startDate || selectDates?.endDate ? 
            `Fecha: ${
              selectDates?.startDate ? 
                new Date(new Date(selectDates?.startDate).getTime() + (24 * 60 * 60 * 1000)).toLocaleDateString('es-ES', { month: 'long', year: 'numeric' }).replace(/\b\w{3}\b/g, match => match.charAt(0).toUpperCase() + match.slice(1)) : " "
            } - ${
              selectDates?.endDate ? 
                new Date(new Date(selectDates?.endDate).getTime() - (24 * 60 * 60 * 1000)).toLocaleDateString('es-ES', { month: 'long', year: 'numeric' }).replace(/\b\w{3}\b/g, match => match.charAt(0).toUpperCase() + match.slice(1)) : " "
            }` 
          : ""
        }
          </p>
          <p>
            {filterOptions.provinces.length > 0
              ? `Provincias: ${nameProvinces}`
              : null}
          </p>
          <p>
            {filterOptions.companies.length > 0
              ? `Sucursales: ${filterOptions.companies}`
              : null}
          </p>
          <p>
            {filterOptions.utilities.length > 0
              ? `Servicio: ${filterOptions.utilities}`
              : null}
          </p>
          <p>
            {filterClass.class.length > 0
              ? `Conceptos: ${filterClass.class}`
              : null}
          </p>
        </div>
      </div>
      <div className="parent-consumpDash" id="divDashConsumption">
        <div className="div0-consumpDash panel firstWidget">
          <h5 className="text-value">{countBills?.length}</h5>
          <label className="text-title">Facturas procesadas</label>
        </div>
        <div className="div1-consumpDash panel firstWidget">
          <h5 className="text-value">{`${(totalServices["AGUA"][0]?.costTotal).toLocaleString('es-AR', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}  m3`}</h5>
          <label className="text-title">Agua</label>
        </div>
        <div className="div2a-consumpDash panel firstWidget">
          {totalServices["GAS NATURAL"][0] ? 
            <>
            <h5 className="text-value">{`${totalServices["GAS NATURAL"][0]?.costTotal}  m3`}</h5>
            <label className="text-title">{totalServices["GAS NATURAL"][0]?.class}</label>
            </>
           : 
            <>
            <h5 className="text-value">No hay consumo</h5>
            <label className="text-title">Gas Consumido</label>
            </>
           }
        </div>
        <div className="div2b-consumpDash panel firstWidget">
          {totalServices["GAS NATURAL"][1] ? 
            <>
            <h5 className="text-value">{`${totalServices["GAS NATURAL"][1]?.costTotal} m3`}</h5>
            <label className="text-title">{totalServices["GAS NATURAL"][1]?.class}</label>
            </>
          : 
            <>
            <h5 className="text-value">No hay consumo</h5>
            <label className="text-title">Gas Cons. a 9300 Kcal/m3</label>
            </>
          }
        </div>
        {orderedElectricidad?.map((item, index) => (
        <div key={index} className={`divE${index}-consumpDash panel firstWidget`}>
          <h5 className="text-value">
            {item ? `${item?.costTotal} ${item?.class === "Tangente fi" || item?.class === "Coseno fi" ? "" : item?.units}` : "No hay consumo"}
          </h5>
          <label className="text-title">{item?.class}</label>
        </div>
        ))}
        <div className="div5-consumpDash panel">
          <BarChart data={filteredTotal} type={"consumption"} filterClass={filterClass?.class}/>
        </div>
        {filterClass?.class?.length < 2 && 
        <>
          <div className="div6-consumpDash panel">
            <DonutUtility filterClass={filterClass?.class} data={filteredTotal} />
          </div>
          <div className="div11-consumpDash panel table-responsive">
            <b className="titles">Proveedores</b>
            <TableVendors type={"consumpVendors"} invoice={filteredTotal} />
          </div>
          <div className="div12-consumpDash panel">
            <TableConsumpAndCosts
              type={filterClass?.class?.length > 0 ? dataElectricity?.[1]?.units : "kWh" || null}
              data={dataElectricity}
              title={"Electricidad - Consumos por sucursal"}
              info={"consumption"}
            />
          </div>
          <div className="div13-consumpDash panel">
            <TableConsumpAndCosts
              type={"m3"}
              data={dataGas}
              title={"Gas - Consumos por sucursal"}
              info={"consumption"}
            />
          </div>
          <div className="div14-consumpDash panel">
            <TableConsumpAndCosts
              type={"m3"}
              data={dataWater}
              title={"Agua - Consumos por sucursal"}
              info={"consumption"}
            />
          </div>
        </>
        } 
          <div className="div9-consumpDash panel">
            <b className="titles">Conceptos</b>
            <TableComponent type={"consumUtility"} info={"consumpItem"} data={data}/>
          </div>
          
        </div>
    </>
  );
};

export default DashConsumption;
