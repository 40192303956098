import { utils, writeFile } from "xlsx";

export const downloadExcel = (
  year,
  setYearSelected,
  yearSelected,
  info,
  setYearGenerate,
  offices,
  filter
) => {
  const data = {};
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  const getMonthName = (monthNumber) => {
    const months = [
      "enero",
      "febrero",
      "marzo",
      "abril",
      "mayo",
      "junio",
      "julio",
      "agosto",
      "septiembre",
      "octubre",
      "noviembre",
      "diciembre",
    ];
    return months[monthNumber];
  };

  const monthsToFetch = [];
  for (let y = currentYear - 1; y <= currentYear; y++) {
    const startMonth = y === currentYear - 1 ? 0 : 0;
    const endMonth = y === currentYear ? currentMonth : 11;
    for (let m = startMonth; m <= endMonth; m++) {
      monthsToFetch.push({ year: y, month: m });
    }
  }

  if (!info) {
    console.error("No se encontraron datos para generar el Excel.");
    return;
  }

  monthsToFetch.forEach(({ year, month }) => {
    const monthName = getMonthName(month);
    const monthDataKey = `${monthName} ${year}`;
    const monthData = info?.groupedByMonth[monthDataKey];
    

    data[monthDataKey] = monthData?.map((item) => ({
      office: item.office,
      consumoTotalMes: parseFloat(item.consumoTotalMes),
      mesProrrateado: item.mesProrrateado,
      unidad: item.unidad || "",
      tiposConsumo: item.tiposConsumo || [],
      days: item.diasCalculados,
    }));


    offices?.forEach((office) => {
      if (!data[monthDataKey]?.some((item) => item?.office === office?.name)) {
        data[monthDataKey]?.push({
          office: office.name,
          consumoTotalMes: 0.0,
          mesProrrateado: monthDataKey,
          unidad: "",
          tiposConsumo: [],
        });
      }
    });
  });

  //aca formateo de datos y armado de prorrateo 

  if(info){
    const filteredData = {};
    Object.keys(data).forEach(month => {
      if (month.includes(year)) {
        filteredData[month] = data[month];
    
      }
    });
    
    


    if (Object.keys(filteredData).length === 0) {
      console.error(`No data found for year ${year}`);
      return;
    }

    const wb = utils.book_new();
    const consumoTypes = ['Energía Consumida', 'Agua Consumida', 'Volumen Registrado', "Energía Renovable", "Energía Autogenerada"];

    consumoTypes.forEach(type => {
      const ws = utils.aoa_to_sheet([]);
      const meses = Object.keys(filteredData);

      const style = {
        font: {
          name: 'Calibri',
          sz: 20,
          bold: true
        }
      };

      const consumptionDescription = type === 'Energía Consumida' || type === "Energía Renovable" || type === "Energía Autogenerada" ? 'Consumos en kWh' :
        type === 'Agua Consumida' ? 'Consumo en m3 (Agua)' : 'Consumo en m3 (Gas)';
      const daysCellDescription = "Dias de consumo contabilizados";

      const styledCell = { v: consumptionDescription, s: style };
      const daysStyledCell = { v: daysCellDescription, s: style };

      const header = ['Nombre del sitio', ...meses];
      if (type === 'Agua Consumida') {
        header.push('', 'MEDIDO', '', ...meses);
        utils.sheet_add_aoa(ws, [[styledCell]], { origin: { r: 0, c: 1 } });
        utils.sheet_add_aoa(ws, [[daysStyledCell]], { origin: { r: 0, c: meses.length + 4 } });
      } else {
        header.push('', ...meses);
        utils.sheet_add_aoa(ws, [[styledCell]], { origin: { r: 0, c: 1 } });
        utils.sheet_add_aoa(ws, [[daysStyledCell]], { origin: { r: 0, c: meses.length + 1 } });
      }
      utils.sheet_add_aoa(ws, [header], { origin: { r: 1, c: 0 } });

      const wscols = [{ wch: 25 }];
      meses.forEach(() => { wscols.push({ wch: 10 }); });
      if (type === 'Agua Consumida') {
        wscols.push({ wch: 10 }); // Espacio antes de "MEDIDO"
        wscols.push({ wch: 10 }); // Columna "MEDIDO"
        wscols.push({ wch: 10 }); // Espacio después de "MEDIDO"
      }
      meses.forEach(() => { wscols.push({ wch: 10 }); });
      ws['!cols'] = wscols;

      ws['!merges'] = [
        { s: { r: 0, c: 1 }, e: { r: 0, c: meses.length } },
        { s: { r: 0, c: type === 'Agua Consumida' ? meses.length + 4 : meses.length + 1 }, e: { r: 0, c: type === 'Agua Consumida' ? meses.length + 6 : meses.length + 3 } }
      ];

      let rowIdx = 2;
      const uniqueSites = new Set();
      const targetSites =
      [
   "SUCURSAL 018"
      ]

      meses.forEach(mes => {
        filteredData[mes]?.forEach(entry => {
          if (entry?.tiposConsumo?.includes(type)) {
            uniqueSites.add(entry?.office?.trim());
          }
        });
      });

      const uniqueSitesArray = Array.from(uniqueSites).sort();
      const filteredSitesArray = filter && filter?.length > 0 ? uniqueSitesArray?.filter(site => filter?.includes(site)) : uniqueSitesArray;

      const addRows = (sites) => {
        sites?.forEach(site => {
          const row = [site.trim()];
          const daysRow = [];
          meses.forEach(mes => {
            const entry = filteredData[mes]?.find(e => e?.office?.trim() === site && e?.tiposConsumo?.includes(type));
            row.push(entry ? entry.consumoTotalMes : 'N/A');
            daysRow.push(entry ? entry.days : 'N/A');
          });

          if (type === 'Agua Consumida') {
            row.push(""); // Espacio antes de "MEDIDO"
            row.push(targetSites.includes(site?.trim()) ? "NO" : "SI"); // "MEDIDO"
            row.push(""); // Espacio después de "MEDIDO"
          } else {
            row.push(""); // Columna vacía
          }

          utils.sheet_add_aoa(ws, [row.concat(daysRow)], { origin: { r: rowIdx++, c: 0 } });
        });
      };

      addRows(filteredSitesArray);

      if (!filter || filter?.length === 0) {
        utils.sheet_add_aoa(ws, [['']], { origin: { r: rowIdx++, c: 0 } });

        const totalsRow = ['TOTAL ' + (type === 'Energía Consumida' || type ==="Energía Renovable" || type === "Energía Autogenerada" ? 'kWh' : 'm3') + ' mensual'];
        const percentagesRow = ['% procesado mensual'];
        meses?.forEach(mes => {
          // Extraer el nombre del mes y el año de la cadena 'mes'
          const [mesNombre, year] = mes.split(' ');
          // Encontrar el índice del mes en el array 'meses'
          const mesIndex = meses.findIndex(m => m.startsWith(mesNombre));
        
          // Calcular el total mensual y los días procesados
          const monthlyTotal = filteredData[mes]
            ?.filter(e => e?.tiposConsumo?.includes(type))
            ?.reduce((acc, curr) => acc + (curr?.consumoTotalMes || 0), 0);
          const days = filteredData[mes]
            ?.filter(e => e?.tiposConsumo?.includes(type))
            ?.reduce((acc, curr) => acc + (curr?.days || 0), 0);
        
          const totalSitesPerMonth = uniqueSites?.size; // Número de sitios activos por mes
        
          // Crear la fecha para el último día del mes correspondiente al año extraído
          const totalExpected = totalSitesPerMonth * new Date(year, mesIndex + 1, 0).getDate();
          const percentage = (days / totalExpected) * 100;
        
          totalsRow?.push(monthlyTotal?.toFixed(2));
          percentagesRow?.push(percentage?.toFixed(2) + '%');
        });
        

        utils.sheet_add_aoa(ws, [totalsRow], { origin: { r: rowIdx++, c: 0 } });
        utils.sheet_add_aoa(ws, [percentagesRow], { origin: { r: rowIdx++, c: 0 } });
      }

      const typeName = type === 'Energía Consumida' ? 'Electricidad' :
      type === 'Volumen Registrado' ? 'Gas' :
      type === 'Energía Renovable' ? 'Energía Renovable' : 
      type === 'Energía Autogenerada' ? 'Energía Autogenerada' : 'Agua';

      utils.book_append_sheet(wb, ws, typeName);
    });

    writeFile(wb, `Reporte de consumos ${year}.xlsx`);
  } else {
    console.error("Data is null or undefined.");
  }

};
